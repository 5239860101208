import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import React, { useMemo, useState } from "react";
import { Tooltip } from "@puzzle/ui";
import { styled, colors, CSS } from "@puzzle/theme";
import rgba from "polished/lib/color/rgba";

import { DetailConfirmedState } from "graphql/types";
import { Confirmed, Rule, Linked, AIContext } from "@puzzle/icons";

import { Text } from "ve";

import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { useToggleFinalizedState } from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { isCreditCard } from "../utils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { WarningDialog } from "components/dashboard/Accounting/LockedPeriods/WarningDialog";
import { LockDayWarning } from "components/dashboard/Accounting/LockedPeriods/LockedPeriod";
import { FinalizeCCModal } from "../FinalizeCCModal";
import { parseDate } from "@internationalized/date";
import { toCalendarMonthString } from "@puzzle/utils";
import { UnlinkTransactionModal } from "../UnlinkTransactionModal";

const DisplayStateContainer = styled("button", {
  backgroundColor: "transparent",
  width: "32px",
  height: "32px",
  border: "1px solid",
  borderRadius: "$ellipse",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  outline: "none",
  transition: "all 0.1s ease-in",

  borderColor: rgba(colors.neutral600, 0.32),
  $$hoverColor: rgba(colors.neutral600, 0.7),

  variants: {
    hideBorder: {
      true: {
        border: "none",
      },
      false: {},
    },
    tooltipAsContent: {
      true: {
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "$1",
        width: "auto",
        padding: "$1h $1h",
        borderRadius: "$1",
      },
      false: {},
    },
  },

  defaultVariants: {
    hideBorder: false,
    tooltipAsContent: false,
  },

  "&:hover, &:focus": {
    borderColor: "$$hoverColor",
  },
});

const USER_CONFIRMED_COLOR = colors.blue300;
const SYSTEM_ASSIGNED_COLOR = colors.neutral600;
const FINALIZED_COLOR = colors.greenalpha;

const StateToIcon: Record<DetailConfirmedState, React.ElementType> = {
  [DetailConfirmedState.Automated]: Rule,
  [DetailConfirmedState.UserAssigned]: Confirmed,
  [DetailConfirmedState.UserRuleAssigned]: Rule,
  [DetailConfirmedState.Finalized]: Confirmed,
  [DetailConfirmedState.Locked]: Linked,
  [DetailConfirmedState.AiAssigned]: AIContext,
};

const StateToColor: Partial<Record<DetailConfirmedState, string>> = {
  [DetailConfirmedState.Automated]: SYSTEM_ASSIGNED_COLOR,
  [DetailConfirmedState.UserAssigned]: USER_CONFIRMED_COLOR,
  [DetailConfirmedState.UserRuleAssigned]: USER_CONFIRMED_COLOR,
  [DetailConfirmedState.Finalized]: FINALIZED_COLOR,
  [DetailConfirmedState.Locked]: FINALIZED_COLOR,
  [DetailConfirmedState.AiAssigned]: USER_CONFIRMED_COLOR,
};

const StateToHelpText: Record<DetailConfirmedState, string> = {
  [DetailConfirmedState.Automated]: "System Suggested Category",
  [DetailConfirmedState.UserAssigned]: "User Assigned Category",
  [DetailConfirmedState.UserRuleAssigned]: "User Rule Assigned Category",
  [DetailConfirmedState.Finalized]: "Finalized Category",
  [DetailConfirmedState.Locked]: "System Linked Transaction",
  [DetailConfirmedState.AiAssigned]: "AI suggested category based on user context",
};

export const StatusIcon = ({
  className,
  transaction,
  state = transaction?.detail?.confirmedState,
  onClick,
  tooltipAsContent = false,
  bordered = true,
  tooltipProps,
  location,
  css: _css,
}: {
  className?: string;
  state?: DetailConfirmedState | null;
  onClick?: React.MouseEventHandler;
  tooltipAsContent?: boolean;
  // I only needed a subset until I remembered CategoryModal is in here.
  // Maybe CategoryModal can also define a subset.
  transaction?: BasicTransactionFragment;
  bordered?: boolean;
  // TODO Can we just look at the current URL in PostHog?
  location?: string;
  tooltipProps?: React.ComponentProps<typeof Tooltip>;
  css?: CSS;
}) => {
  const { lockedPeriodDate, isWithinLockedPeriod, company } = useActiveCompany<true>();
  const toggleFinalizedState = useToggleFinalizedState(transaction);

  // TODO lucy remove this asap after merging GW PR
  const creditCard = useMemo(
    () => transaction && isCreditCard(transaction, transaction?.detail.category),
    [transaction]
  );

  const linked = useMemo(() => Boolean(transaction && transaction.links.length), [transaction]);

  const inLockedPeriod = useMemo(
    () =>
      Boolean(
        transaction?.date && lockedPeriodDate && isWithinLockedPeriod(parseDate(transaction.date))
      ),
    [transaction?.date, lockedPeriodDate, isWithinLockedPeriod]
  );

  const [isConfirmCreditCard, setIsConfirmCreditCard] = useState(false);
  const [isLockedPeriod, setIsLockedPeriod] = useState(false);
  const [isUnlinkTransaction, setIsUnlinkTransaction] = useState(false);

  const content = useMemo(() => {
    const Icon = state ? StateToIcon[state] : () => <></>;
    const color = state ? StateToColor[state] : undefined;
    const icon = <Icon color={color} />;
    if (bordered) {
      const css = omitBy(
        {
          ..._css,
          borderColor: !tooltipAsContent && color && rgba(color, 0.32),
          $$hoverColor: color && rgba(color, 0.7),
        },
        isNil
      );
      return (
        <DisplayStateContainer
          hideBorder={state === DetailConfirmedState.Locked}
          tooltipAsContent={tooltipAsContent}
          css={css}
          className={className}
          onClick={(e) => {
            onClick?.(e);

            if (transaction && !e.defaultPrevented) {
              e.stopPropagation();

              if (!transaction.pending && transaction.detail.postedAt === null) {
                setIsLockedPeriod(true);
                return;
              }

              if (!inLockedPeriod) {
                const isFinalized =
                  transaction?.detail.confirmedState === DetailConfirmedState.Finalized;
                if (creditCard && !isFinalized && !linked) {
                  setIsConfirmCreditCard(true);
                } else if (linked) {
                  setIsUnlinkTransaction(true);
                } else {
                  toggleFinalizedState();
                }
              }
            }
          }}
        >
          {icon}
          {tooltipAsContent && state && (
            <Text variant="headingS" color="neutral100">
              {StateToHelpText[state]}
            </Text>
          )}
        </DisplayStateContainer>
      );
    }

    return <span>{React.cloneElement(icon, { onClick, className })}</span>;
  }, [
    state,
    bordered,
    onClick,
    className,
    _css,
    transaction,
    inLockedPeriod,
    creditCard,
    linked,
    toggleFinalizedState,
  ]);

  return (
    <>
      <Tooltip
        content={state && StateToHelpText[state]}
        side="right"
        sideOffset={4}
        {...tooltipProps}
      >
        {content}
      </Tooltip>

      {transaction && (
        <>
          {creditCard && (
            <FinalizeCCModal
              open={isConfirmCreditCard}
              onConfirm={() => {
                toggleFinalizedState();
                setIsConfirmCreditCard(false);
              }}
              onCancel={() => setIsConfirmCreditCard(false)}
            />
          )}

          {isLockedPeriod && (
            <WarningDialog
              period={lockedPeriodDate ? toCalendarMonthString(lockedPeriodDate) : null}
              warnings={[LockDayWarning.TransactionWarning]}
              open={isLockedPeriod}
              onOpenChange={setIsLockedPeriod}
              transactionId={transaction.id}
              transactionDate={parseDate(transaction.date)}
            />
          )}

          {linked && (
            <UnlinkTransactionModal
              transactionId={transaction.id}
              companyId={company.id}
              open={isUnlinkTransaction}
              onOpenChange={setIsUnlinkTransaction}
            />
          )}
        </>
      )}
    </>
  );
};
